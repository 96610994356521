import { PASS_TOTAL_SUPPLY, TOWNS_TOTAL_SUPPLY } from "constants/listing";
import ContractsMap from "hooks/requests/polygon/constants";
import { TokenAddress } from "types/listing";
import { TotalSupplyResponse } from "types/responses";
import { web3Polygon } from "..";
import nftAbi from "./nftAbi";
import isDataNeedUpdate from "./utils";

type IGetNftTotalSupplyResponse = bigint;

const getNftTotalSupply = async (
  nft: TokenAddress,
): Promise<TotalSupplyResponse> => {
  // TODO remove this code when method totalSupply for towns is made in blockchain
  if (nft === "town") return { totalSupply: TOWNS_TOTAL_SUPPLY };
  if (nft === "pass") return { totalSupply: PASS_TOTAL_SUPPLY };

  const sessionKey = `${nft}_totalSupply`;
  const sessionTimeStampKey = `${nft}_totalSupply_timestamp`;
  const cachedSessionData = sessionStorage.getItem(sessionKey);
  const cachedSessionTimeStamp = sessionStorage.getItem(sessionTimeStampKey);

  if (
    cachedSessionData &&
    cachedSessionTimeStamp &&
    !isDataNeedUpdate(+cachedSessionTimeStamp)
  ) {
    return { totalSupply: cachedSessionData };
  }

  const nftContract = new web3Polygon.eth.Contract(nftAbi, ContractsMap[nft]);
  const response: IGetNftTotalSupplyResponse = await nftContract.methods
    .totalSupply()
    .call();

  const result = response.toString();

  if (result) {
    sessionStorage.setItem(sessionKey, result);
    sessionStorage.setItem(
      sessionTimeStampKey,
      new Date().getTime().toString(),
    );
  }

  return { totalSupply: result };
};

export default getNftTotalSupply;
