import React, { useEffect, useRef } from "react";
import cn from "classnames";
import s from "./TokensChartAce.module.scss";

function TokensChartAce() {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const iframe = document.createElement("iframe");
      iframe.height = "100%";
      iframe.width = "100%";
      iframe.id = "geckoterminal-embed";
      iframe.title = "GeckoTerminal Embed";
      iframe.src =
        "https://www.geckoterminal.com/ru/polygon_pos/pools/0x129ff8de02ed70aac5a25de8d9acd3a5c1018acc?embed=1&info=0&swaps=0";
      iframe.allow = "clipboard-write";
      iframe.allowFullscreen = true;

      chartRef.current.appendChild(iframe);
    }

    // Cleanup function to remove iframe on component unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.innerHTML = "";
      }
    };
  }, []);

  return <div ref={chartRef} className={cn(s.tokensChart)} />;
}

export default TokensChartAce;
